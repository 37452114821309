// import { Observable } from 'rxjs';
import moment from "moment";


const typeCache: { [label: string]: boolean } = {};

type Predicate = (oldValues: Array<any>, newValues: Array<any>) => boolean;

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels are unique.
 *
 * @param label
 */
export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unqiue"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}

/**
 * Runs through every condition, compares new and old values and returns true/false depends on condition state.
 * This is used to distinct if two observable values have changed.
 *
 * @param oldValues
 * @param newValues
 * @param conditions
 */
export function distinctChanges(
  oldValues: Array<any>,
  newValues: Array<any>,
  conditions: Predicate[]
): boolean {
  if (conditions.every(cond => cond(oldValues, newValues))) {
    return false;
  }
  return true;
}

/**
 * Returns true if the given value is type of Object
 *
 * @param val
 */
export function isObject(val: any) {
  if (val === null) {
    return false;
  }

  return typeof val === 'function' || typeof val === 'object';
}

/**
 * Capitalizes the first character in given string
 *
 * @param s
 */
export function capitalize(s: string) {
  if (!s || typeof s !== 'string') {
    return s;
  }
  return s && s[0].toUpperCase() + s.slice(1);
}

/**
 * Uncapitalizes the first character in given string
 *
 * @param s
 */
export function uncapitalize(s: string) {
  if (!s || typeof s !== 'string') {
    return s;
  }
  return s && s[0].toLowerCase() + s.slice(1);
}

/**
 * Flattens multi dimensional object into one level deep
 *
 * @param obj
 * @param preservePath
 */
export function flattenObject(ob: any, preservePath: boolean = false): any {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) {
      continue;
    }

    if (typeof ob[i] === 'object') {
      const flatObject = flattenObject(ob[i], preservePath);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) {
          continue;
        }

        const path = preservePath ? i + '.' + x : x;

        toReturn[path] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }

  return toReturn;
}

/**
 * Returns formated date based on given culture
 *
 * @param dateString
 * @param culture
 */
export function localeDateString(dateString: string, culture: string = 'en-EN'): string {
  const date = new Date(dateString);
  return date.toLocaleDateString(culture);
}
export function getBoolean(value) {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}
/* Get Exact date */
export function getExactDate(date: any): Date {
  var d1 = moment(date).format("DD/MM/YYYY");
  var day = d1.split("/")[0];
  var month = d1.split("/")[1];
  var year = d1.split("/")[2];
  return new Date(year + '-' + month + '-' + day);
  //return new Date(date);
}

export function getExactDateWithoutTime(date: any): Date {
  var d1 = moment(date).format("DD/MM/YYYY");
  var day = d1.split("/")[0];
  var month = d1.split("/")[1];
  var year = d1.split("/")[2];
  var d = new Date(year + '-' + month + '-' + day);
  d.setHours(0, 0, 0, 0); // Set time components to 0
  return d;
}

export function getExactDateWithoutTimeWithFormate(date: any): Date {
  // var d1 = moment(date).format(format);
  var day = date.split("/")[0];
  var month = date.split("/")[1];
  var year = date.split("/")[2];
  var d = new Date(year + '-' + month + '-' + day);
  d.setHours(0, 0, 0, 0); // Set time components to 0
  return d;
}

export function getExactDateWitTime(date: any): Date {
  // var d1 = moment(date).format("DD/MM/YYYY");
  const localMoment = moment.utc(date).local();
  const localDate = localMoment.toDate();
  return localDate;
}

export function isBetween(date: Date, startDate: Date, endDate: Date): boolean {
  return moment(date).isBetween(startDate, endDate, 'days', '[]');
}

export function getAgeYear(startDate:Date,endDate:Date):any
{
  
var startMDate = moment.utc(startDate).format("DD/MM/YYYY");
var endMDate = moment.utc(endDate).format("DD/MM/YYYY");

var day1 = startMDate.split("/")[0];
var month1 = startMDate.split("/")[1];
var year1 = startMDate.split("/")[2];

var day2= endMDate.split("/")[0];
var month2 = endMDate.split("/")[1];
var year2 = endMDate.split("/")[2];
var a = moment.utc([  year1,month1,day1],"yyyy-MM-DD");
var b = moment.utc([  year2,month2,day2],"yyyy-MM-DD");

console.log(a);
console.log(b);

var yearAge=moment.utc(b).diff(moment.utc(a),"years",true); // "a day ago



//var yearAge=moment(endDate).hours(0).minutes(0).seconds(0).milliseconds(0).diff(moment( startDate).hours(0).minutes(0).seconds(0).milliseconds(0), 'years', true);
//var yearAge=moment.utc(endDate).local().diff(moment.utc(startDate).local(), 'years', true);
 

// var day = endMDate.split("/")[0];
// var month = endMDate.split("/")[1];
// var year = startMDate.split("/")[2];
// var extEndDate = new Date(year + '-' + month + '-' + day);
  // if(new Date(endMDate)<extEndDate)

  //   {yearAge=yearAge-1;}
return yearAge; // 1.75
}
import { Injectable } from '@angular/core';

import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { ToasterService } from './toaster.service';

@Injectable()
export class UtilityService {
  public FielDictionaryData: any;
  public grouprightsdata: any;
  constructor(
    public toaster: ToasterService,
    public auth: AuthService,
  ) {
    // if (environment.networkLogger) {
    //   networkLoggerService.start(8000, 60000);
    // }
  }
  // uploadImage(files): Observable<ImageResult[]> {
  //   const formData: FormData = new FormData();
  //   files.forEach(t => {
  //     formData.append('fileKey', t, t.name);
  //   });
  //   return this.data
  //     .post('Gallery/GallerySave', formData)
  //     .map((data: ImageResult[]) => data);
  // }
  // saveException(exception): Observable<any> {
  //   return this.data.post('NetworkLog/SaveClientException', exception);
  // }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  // getGroupRightsForUser(): Observable<TreeNode[]> {
  //   return this.data
  //     .get('GroupRights')
  //     .map((data: TreeNode[]) => data);
  // }
}

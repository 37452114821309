/** Angular core modules */
import { NgModule, ModuleWithProviders } from '@angular/core';
/** Custom Utilities Services */
import { ValidationService } from './validation.service';
import { UtilityService } from './utility.service';
import { ToasterService } from './toaster.service';


@NgModule()
export class UtilityModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: UtilityModule,
      providers: [ValidationService, UtilityService, ToasterService]
    };
  }
}
